<template>
  <transition name="fade">
    <v-alert
      v-if="api.isError"
      dense
      border="left"
      type="warning"
    >
      {{api.error}}
    </v-alert>
  </transition>
</template>

<script>
  export default {
    props:['api']
  }
</script>
