import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import bootstrap from './plugins/bootstrap'
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import i18n from './plugins/i18n'
import '@/mixins/locale'
import '@/mixins/route'
import '@/mixins/string'
import _ from 'lodash'
import moment from 'moment'
import JsonCSV from 'vue-json-csv';
import { permission } from './utils/permission'
import {date} from './utils/date'; 
//BOC
import {version} from './../package.json'
Vue.prototype.$version = version
//EOC
//BOC:[api]
import axios from 'axios'

Vue.config.errorHandler = function(err,vm,info) {
    console.log('Error:'+err.toString()+'Info: '+info);
    if(vm) {
        console.error('Component name:', vm.$options.name || 'Anonymous');
        console.error('Props:', vm.$props);
        console.error('Component instance:', vm);
    }
    console.log("error :",err.message);
    console.error('Stack trace:', err.stack);
   
}

Vue.prototype.$axios = axios
var api = {
    servers: {
        api: process.env.VUE_APP_SERVER_API,
    },
    fetch: async(props) => {
        var params = {}
        var headers = {}
        var method = 'post'
        if (typeof props.params != 'undefined') params = props.params
        if (typeof props.method != 'undefined') method = props.method
        if (typeof props.headers != 'undefined') headers = props.headers
        //BOC:[auto insert token]
        if (typeof store.state.auth.token != 'undefined') headers.Authorization = 'Bearer ' + store.state.auth.token
        //EOC
        props.callbackReset();
        //BOX: [ instance calls the api  ]
        try {
            await axios({
                    method: method,
                    headers: headers,
                    url: props.url,
                    data: params,
                })
                .then(
                    (response) => {
                        if (response.data == null) {
                            props.callbackError('Item not found.');
                        } else if (response.data.error) {
                            props.callbackError(response.data.error);
                        } else {
                            props.callbackSuccess(response.data);
                        }
                    },
                    (error) => {
                        if(error.response.status===401){
                            router.push({name:'PageAdminLogout'});
                        }
                        if (typeof error.response != 'undefined' && typeof error.response.data != 'undefined') {
                            var err;
                            if (typeof error.response.data.errors != 'undefined' && error.response.data.errors) {
                                //read Laravel ValidationException errors message
                                var errors = error.response.data.errors;
                                err = errors[Object.keys(errors)[0]];
                            } else if (typeof error.response.data.message != 'undefined') {
                                err = error.response.data.message;
                                if (err == 'Authorization token expired') {
                                    //BOC:[locale]
                                    var locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
                                    if (locale == '/') locale = 'en'
                                    //EOC
                                    window.location.replace('/' + locale + '/error/expired');
                                }

                            } else {
                                err = error;
                            }
                            props.callbackError(err);
                        } else {
                            props.callbackError(error);
                        }
                    }
                );
        } catch (e) {
            props.callbackError(e);
        }
    },
}
Vue.prototype.$api = api
//EOC
//BOC: [env]
Vue.prototype.$env = _.clone(process.env)
//EOC
//BOC: [vue prototype for lodash]
Vue.prototype.$_ = _
//EOC
//BOC
Vue.prototype.$moment = moment
Vue.prototype.$permission = permission;
Vue.prototype.$date = date;
//EOC
//BOC
//EOC

//BOC:[common components]
Vue.component('ADateFormat',require('./components/common/date/ADateFormat').default);
Vue.component('ALoader', require('./components/common/ALoader.vue').default);
Vue.component('AError', require('./components/common/AError.vue').default);
Vue.component('ASuccess',require('./components/common/ASuccess.vue').default);
Vue.component('AWarning',require('./components/common/AWarning.vue').default);
Vue.component('AConfirmation',require('./components/common/AConfirmation.vue').default);
Vue.component('ACopyAbleText',require('./components/common/text/ACopyAbleText.vue').default);
Vue.component('ARedirectBack',require('./components/common/navigation/ARedirectBack.vue').default);
Vue.component('JsonCSV',JsonCSV);
Vue.component("AFilterPaginate",require("./components/common/filter/AFilterPaginate.vue").default);
//EOC
//BOC:[meta]
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
//EOC

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    mavonEditor,
    bootstrap,
    i18n,
    render: h => h(App)
}).$mount('#app')